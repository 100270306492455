import { Component, OnInit, Inject } from '@angular/core';
import { BillingService } from '@app/service/billing/billing.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddresskeybillingComponent } from '../../addresskeybilling/addresskeybilling.component';
import { IndicatorStore } from 'app/state/store';
import { IndicatorQuery } from 'app/state/query';
import {PaynowService} from '@app/service/roundProgress/paynow/paynow.service'
import { BillingstringComponent } from '../../billingstring/billingstring.component';
import { BillingstateComponent } from '../../billingstate/billingstate.component';
import { BillingphonennumberfieldComponent } from '../../billingphonennumberfield/billingphonennumberfield.component';
import { BillingemailComponent } from '../../billingemail/billingemail.component';
import { LoggerService } from '@app/service/logger/logger.service';
import { BillingselectComponent } from '../../billingselect/billingselect.component';
import { BillinginputComponent } from '../../billinginput/billinginput.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '@app/service/utils/utils.service';
import { ignoreElements } from 'rxjs/operators';


@Component({
  selector: 'app-billingnew',
  templateUrl: './billingnew.component.html',
  styleUrls: ['./billingnew.component.scss']
})
export class BillingnewComponent implements OnInit {
  billingSchema ={
    form: [],
    schema: []
  }
  customWidgets;
  sharedData;
  billingId;
  url;//this.Params.params.payNow;
  payNowurl: string;
  accountType: string;
  repId:any;
  zipValue: any;
  productType: string;
  billingMobile:any;
  billingEmail:any;
  backupBilling:any;
  billingAccountName: string;
  BTNNumber: string;


  constructor(public dialogRef: MatDialogRef<BillingnewComponent>,
    private billing: BillingService, private indicatorQuery: IndicatorQuery,
    private indicatorStore: IndicatorStore, private spinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private PaynowService: PaynowService,
    private logger: LoggerService,
    private Utils: UtilsService,) {
    this.initCustomWidgets()
  }

  ngOnInit() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    this.backupBilling = JSON.parse(JSON.stringify(this.sharedData));
    this.repId = this.sharedData.order.opportunity.owner.operatorId
    this.billing.getBillingSchema().subscribe(res =>{
      if(this.data.title == "Create"){
        this.billingSchema.schema = res.billing[0].schema;
        this.accountType = 'Monthly Advance Pay' ;
        this.productType = this.data.productType;
        if (this.sharedData.account.address.country == 'NZ') {
          this.accountType = 'Invoice' ;
        }else if (this.productType && this.productType.toLowerCase() =='digital' ) {
          this.accountType = 'Monthly Advance Pay' ;
        } else if (this.productType && this.productType.toLowerCase() =='print') {
          this.accountType = 'Invoice' ;
        }
          let formFromAPI = res.billing[0].form;
          let tempKGENForm = [];

          for (var j = 0; j < formFromAPI.length; j++) {
            if (!formFromAPI[j].system || (formFromAPI[j].system && formFromAPI[j].system.toUpperCase() !== "BRM")) {
                tempKGENForm.push(formFromAPI[j]);
            }
            for (var i = 0; i < tempKGENForm.length; i++) {
              if (tempKGENForm[i].key === "AddressKey") {
                tempKGENForm[i].type = "addresskey";
              }
            }
          }
          tempKGENForm.push({
            "type": "submit",
            "title": "Save",
            "htmlClass": "pull-right billingsave"
          });
          this.billingSchema.form = tempKGENForm;

      } else {
        this.billingId = this.data.dialogform._cell.row.data.billingAccountId;
        this.sharedData.selectedBillAccountId = this.billingId;
        
        //FUL-26430
        this.billingAccountName = this.data.dialogform._cell.row.data.accountName;
        this.BTNNumber = this.data.dialogform._cell.row.data.phone;
        this.billingMobile = (this.data.dialogform._cell.row.data.cellular && this.data.dialogform._cell.row.data.cellular.length >= 10) ? this.data.dialogform._cell.row.data.cellular : "";
        this.billingEmail = this.data.dialogform._cell.row.data.emailAddress;
        this.billingSchema.schema = res.billing[0].schema;
        this.accountType = this.data.dialogform._cell.row.data.billingType;
        let formFromAPI = res.billing[0].form;
        let tempKGENForm = [];

        for (var j = 0; j < formFromAPI.length; j++) {
          if (!formFromAPI[j].system || (formFromAPI[j].system && formFromAPI[j].system.toUpperCase() !== "BRM")) {
            tempKGENForm.push(formFromAPI[j]);
          }
          for (var i = 0; i < tempKGENForm.length; i++) {
            if (tempKGENForm[i].key === "AddressKey") {
              tempKGENForm[i].type = "addresskey";
            }
            if (tempKGENForm[i].key === "BillingAccountType") {
              tempKGENForm[i].type = "input";
            }
          }
        }
        tempKGENForm.push({
          "type": "submit",
          "title": "Save",
          "htmlClass": "pull-right billingsave"
        });
        this.billingSchema.form = tempKGENForm;
        this.indicatorStore.update((state) => {
          return {
            indicator:this.sharedData,
            updatedFrom: "[BillingnewComponent] open",
            isloaded: true,
          };
        });
      }
    })
  }
  initCustomWidgets() {
    this.customWidgets =
		{
      select :BillingselectComponent,
      string : BillingstringComponent,
      Email: BillingemailComponent,
      state: BillingstateComponent,
      phonenumberfield:BillingphonennumberfieldComponent,
      addresskey: AddresskeybillingComponent,
      input: BillinginputComponent

		}
  }
  getDigitalItemInd(accountType) {
    if ('Invoice' === accountType || accountType.includes('Invoice')) {
      if (this.sharedData.account.address.country == 'NZ') {
        return "Y";
      } else {
        return "N";
      }
    } else if ('MAP' === accountType || 'Monthly Advance Pay' === accountType) {
        return "Y";
    } else {
      return "N";
    }
  };

  getDigitalAdvPayInd(accountType) {
      let billing;
      billing = this.billingId && this.sharedData && this.sharedData.billing && this.sharedData.billing.billing  ? this.sharedData.billing.billing.find(billing => billing.billingAccountId == this.billingId) : null;
      if ('Invoice' === accountType || (billing && "N" === billing.digitalAdvPayInd)) {
          return "N";
      } else if ('MAP' === accountType || 'Monthly Advance Pay' === accountType || ( billing && "Y" === billing.digitalAdvPayInd)) {
          return "Y";
      } else {
        return "N";
      }
  };
  onUpdateBillingFields(data,fieldName){
    if (fieldName == "Mobile"){
      this.billingMobile = data;
    }else{
      this.BTNNumber = data;
    }
    
  }
  onUpdateBillingemail(data){
    this.billingEmail = data;
  }
  close(){
    this.indicatorStore.update((state) => {
      return {
        indicator:this.backupBilling,
        updatedFrom: "[BillingnewComponent] close",
        isloaded: true,
      };
    });
    this.dialogRef.close();
  }
  OnSubmitBiling() {
    this.indicatorQuery.getIndicator().subscribe((res) => {
      this.sharedData = { ...res };
    });
    const countryCode = this.sharedData.account.address.country;
    const phone = this.sharedData.account.phone;
    // this.billing.lookupPhoneNumber(phone, countryCode)
    //   .then((response) => {
    //     if (response && response.data && (response.data.valid != "false")) {
    //       // this.indicatorQuery.getIndicator().subscribe((res) => {
          //   this.sharedData = { ...res };
          // });
          let upadatedName = JSON.parse(JSON.stringify(this.sharedData));
          let bilingDetails = true;
          this.PaynowService.getPayNowLink(null);
          this.PaynowService.finalPaynowURL;
          this.payNowurl = this.PaynowService.finalPaynowURL;
          let zip5 = upadatedName.account.address.zip && upadatedName.account.address.zip.includes("-") ? upadatedName.account.address.zip.split('-')[0] : upadatedName.account.address.zip;
          let zip4 = upadatedName.account.address.zip && upadatedName.account.address.zip.includes("-") ? upadatedName.account.address.zip.split('-')[1] : "";
          let accountDetails: any = {};
          if (this.data.title == "Create") {
            accountDetails = {
              "productCode": "",
              "type": "UpsertBilling",
              "id": "",
              "callerId": "UCC",
              "correlationId": "",
              "sourceId": "",
              "billing": {
                "customerId": (upadatedName.account.kgenCustomerId),
                "billingName": upadatedName.account.name,
                // "btn": {
                //   "telephoneType": {
                //     "NPA": (upadatedName.account.phone !== undefined) ? upadatedName.account.phone.substring(1, 4) : "",
                //     "COP": (upadatedName.account.phone !== undefined) ? upadatedName.account.phone.substring(6, 9) : "",
                //     "LINE": (upadatedName.account.phone !== undefined) ? upadatedName.account.phone.substring(10, 14) : "",
                //   }
                // },
                "btn": (upadatedName.account.phone !== undefined) ? upadatedName.account.phone : "",

                "billingAddress": {
                  "streetAddress": upadatedName.account.address.street1,//trim() + ((billingCtrl.model["Address2"] !== undefined) ? (billingCtrl.model["Address2"].trim() !== undefined) : ""),
                  "cityName": upadatedName.account.address.city,
                  "stateAbbr": upadatedName.account.address.state,
                  "country": upadatedName.account.address.country,
                  "zip5": zip5,
                  "zip4": zip4,
                  "suburb": (upadatedName.account.address.country !== 'US' && upadatedName.account.address.street2) ? upadatedName.account.address.street2 : ""
                },
                // "cellularNum": {
                //   "telephoneType": {
                //     "NPA": (this.billingMobile !== undefined) ? this.billingMobile.substring(1, 4) : "",
                //     "COP": (this.billingMobile !== undefined) ? this.billingMobile.substring(6, 9) : "",
                //     "LINE": (this.billingMobile !== undefined) ? this.billingMobile.substring(10, 14) : ""
                //   }
                // },
                "cellularNum": (this.billingMobile !== undefined) ? this.billingMobile : "",
                /*"emailAddress":(billingCtrl.model["Email"] !== undefined) ? billingCtrl.model["Email"] : "",*/
                // "emailAddress": "", //Fix for DE13938
                "emailAddress": this.billingEmail,
                "accountType": "L",
                "accountSubType": "R",
                "attn": upadatedName.account.attn ? upadatedName.account.attn : '',
                "digitalItemInd": this.getDigitalItemInd(this.accountType),
                "digitalAdvPayInd": this.getDigitalAdvPayInd(this.accountType),
                "repId": this.repId,
              }
            };
          } else {
            accountDetails = {
              "productCode": "",
              "type": "UpsertBilling",
              "id": this.billingId,
              "callerId": "UCC",
              "correlationId": "",
              "sourceId": "",
              "billing": {
                "customerId": (upadatedName.account.kgenCustomerId),
                "billingName": (upadatedName.account.updatedBillName || this.billingAccountName),
                // "btn": {
                //   "telephoneType": {
                //     "NPA": (this.BTNNumber !== undefined) ? this.BTNNumber.substring(1, 4) : "",
                //     "COP": (this.BTNNumber !== undefined) ? this.BTNNumber.substring(6, 9) : "",
                //     "LINE": (this.BTNNumber !== undefined) ? this.BTNNumber.substring(10, 14) : ""
                //   }
                // },
                "btn": (this.BTNNumber !== undefined) ? this.BTNNumber : "",

                "billingAddress": {
                  "streetAddress": upadatedName.account.address.street1,//trim() + ((billingCtrl.model["Address2"] !== undefined) ? (billingCtrl.model["Address2"].trim() !== undefined) : ""),
                  "cityName": upadatedName.account.address.city,
                  "stateAbbr": upadatedName.account.address.state,
                  "country":  upadatedName.account.address.country,
                  "zip5": zip5,
                  "zip4": zip4,
                  "suburb": (upadatedName.account.address.country !== 'US' && upadatedName.account.address.street2) ? upadatedName.account.address.street2 : ""
                },
                // "cellularNum": {
                //   "telephoneType": {
                //     "NPA": (this.billingMobile !== undefined) ? this.billingMobile.substring(1, 4) : "",
                //     "COP": (this.billingMobile !== undefined) ? this.billingMobile.substring(6, 9) : "",
                //     "LINE": (this.billingMobile !== undefined) ? this.billingMobile.substring(10, 14) : ""
                //   }
                // },
                "cellularNum":  (this.billingMobile !== undefined)?this.billingMobile : "",
                /*"emailAddress":(billingCtrl.model["Email"] !== undefined) ? billingCtrl.model["Email"] : "",*/
                // "emailAddress": "", //Fix for DE13938
                "emailAddress": this.billingEmail,
                "accountType": "L",
                "accountSubType": "R",
                "attn": upadatedName.account.attn ? upadatedName.account.attn : '',
                "digitalItemInd": this.getDigitalItemInd(this.accountType),
                "digitalAdvPayInd": this.getDigitalAdvPayInd(this.accountType),
                "repId": this.repId,
              }
            };
          }

          if (this.repId == null || this.repId.toString().length > 6 || this.repId.toString().match(/[a-z]/i)) {
            let errorMsg = "<strong>Source system : " + 'Salesforce' + "</strong><br><strong>" + 'Description' + ": </strong><strong>" + 'Invalid repid ' + this.repId + ' It should not be more than 6 characters' + ":</strong><br>";
            this.logger.logError(errorMsg);
            this.spinnerService.hide();
            this.dialogRef.close();
            return;
          }
          // if (accountDetails.billing.billingName === "" || accountDetails.billing.btn.telephoneType.NPA === "" || accountDetails.billing.billingAddress.streetAddress === "" || accountDetails.billing.billingAddress.cityName === "" || accountDetails.billing.billingAddress.zip5 === "") {
            if(accountDetails.billing.billingName === "" || accountDetails.billing.btn === "" || accountDetails.billing.billingAddress.streetAddress === "" || accountDetails.billing.billingAddress.cityName === "" || accountDetails.billing.billingAddress.zip5 === ""){
            this.logger.logError('Provide valid Billing details');
            bilingDetails = false;
          }
          if (bilingDetails) {
            this.spinnerService.show();
            if (this.data.title == "Create") {
              let page = this.data.page ? this.data.page : "";
              this.billing.create(accountDetails, page).subscribe((response: any) => {
                this.billing._get(upadatedName.account.kgenCustomerId).then((res: any) => {
                  let responseUpdate = res;
                  if (!(upadatedName && upadatedName.billing && upadatedName.billing.billing))
                    upadatedName.billing = {};
                  upadatedName.billing.billing = responseUpdate.billing;
                  let billing = responseUpdate && responseUpdate.billing ? responseUpdate.billing.find(billing => billing.billingAccountId == response.accountId) : null;
                  if (billing && ((this.productType && this.productType.toLowerCase() == 'print') || this.accountType.toLowerCase() == 'invoice')) {
                    let obj = this.pushBillingData(billing, 'Invoice', 'I');
                    if (upadatedName.printBilling) {
                      upadatedName.printBilling.push(obj);
                    } else {
                      upadatedName['printBilling'] = [];
                      upadatedName.printBilling.push(obj);
                    }
                    if(this.sharedData.account.address.country == 'NZ' && this.accountType.toLowerCase() == 'invoice') {
                      if (upadatedName.digitalBilling) {
                        upadatedName.digitalBilling.push(obj);
                      } else {
                        upadatedName['digitalBilling'] = [];
                        upadatedName.digitalBilling.push(obj);
                      }
                      upadatedName = this.pushToItemBillingArray(upadatedName, obj);
                    }
                  } else if (billing && ((this.productType && this.productType.toLowerCase() == 'digital') || this.accountType.toLowerCase() == 'map' || this.accountType == 'Monthly Advance Pay')) {
                    let obj;
                    if (this.sharedData.account.address.country == 'NZ') {
                      if(this.accountType.toLowerCase() == 'invoice') {
                        obj = this.pushBillingData(billing, 'Invoice', 'I');
                      }
                    } else {
                      obj = this.pushBillingData(billing, 'MAP', 'M');
                    }
                    if (upadatedName.digitalBilling) {
                      upadatedName.digitalBilling.push(obj);
                    } else {
                      upadatedName['digitalBilling'] = [];
                      upadatedName.digitalBilling.push(obj);
                    }
                    upadatedName = this.pushToItemBillingArray(upadatedName, obj);

                    // if (upadatedName.digitalBilling) {
                    //   upadatedName.digitalBilling.push(obj);
                    //   for (let item in upadatedName.items) {
                    //     // upadatedName.items[item].billingArray.push(obj);
                    //     if (
                    //       upadatedName &&
                    //       upadatedName.items &&
                    //       upadatedName.items[item] &&
                    //       Array.isArray(upadatedName.items[item].billingArray)
                    //     ) {
                    //       upadatedName.items[item].billingArray.push(obj);
                    //     } else {
                    //       console.error('Properties are undefined or not in the expected format.');
                    //     }

                    //   }
                    // } else {
                    //   upadatedName['digitalBilling'] = [];
                    //   upadatedName.digitalBilling.push(obj);
                    //   for (let item in upadatedName.items) {
                    //     if (upadatedName && upadatedName.items && upadatedName.items[item] && upadatedName.items[item].billingArray) {
                    //       upadatedName.items[item].billingArray.push(obj);
                    //     }
                    //   }
                    // }
                  }
                  upadatedName.account = this.backupBilling.account;
                  this.indicatorStore.setLoading(true);
                  this.indicatorStore.update((state) => {
                    return {
                      indicator: upadatedName,
                      updatedFrom: "[BillingnewComponent] OnSubmitBiling",
                      isloaded: true,
                    };
                  });
                  this.indicatorStore.setLoading();
                  this.logger.logSuccess("Successfully saved new Billing Account");
                  if (response.accountId)
                    this.PaynowService.getPayNowLink(response.accountId);
                  this.PaynowService.finalPaynowURL;
                  this.payNowurl = this.PaynowService.finalPaynowURL;
                  // this.payNowurl = this.payNowurl +encodeURIComponent('billing_id=' + response.accountId) ;
                  if (this.accountType.toLowerCase() == 'map' || this.accountType == 'Monthly Advance Pay') {
                    window.open(this.payNowurl, '_blank')
                  }
                  this.spinnerService.hide();
                  this.dialogRef.close(response);
                }, err => {
                  if (err && err.error && err.error.response && err.error.response.esbServiceFault) {
                    var errorObject = err.error.response.esbServiceFault;
                    var errorSystem = errorObject.sourceSystem;
                    var errorCode = errorObject.exceptionDetail[0].errorCode;
                    var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                    var errorMsg = "<strong>Transaction : " + (err.error.response.transactionid ? err.error.response.transactionid : err.error.response.transactionId) + "</strong><br><strong>" + errorSystem + ": </strong><strong>" + errorCode + ":</strong><br>" + errorDesc;
                    this.logger.logError(errorMsg);
                  }
                  this.spinnerService.hide();
                  this.dialogRef.close(response);
                })

              }, err => {
                if (err.error && err.error.response && err.error.response.esbServiceFault) {
                  var errorObject = err.error.response.esbServiceFault;
                  var errorSystem = errorObject.sourceSystem;
                  var errorCode = errorObject.exceptionDetail[0].errorCode;
                  var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                  var errorMsg = "<strong>Transaction : " + (err.error.response.transactionid ? err.error.response.transactionid : err.error.response.transactionId) + "</strong><br><strong>" + errorSystem + ": </strong><strong>" + errorCode + ":</strong><br>" + errorDesc;
                  this.logger.logError(errorMsg);
                }
                this.indicatorStore.update((state) => {
                  return {
                    indicator: this.backupBilling,
                    updatedFrom: "[BillingnewComponent] CreateKGENError",
                    isloaded: true,
                  };
                });
                this.spinnerService.hide();
                this.dialogRef.close(null);

              });
            } else {
              let page = this.data.page ? this.data.page : "";
              this.billing.update(accountDetails.id, accountDetails, page).subscribe
                ((response: any) => {
                  this.billing._get(upadatedName.account.kgenCustomerId).then((res: any) => {
                    let responseUpdate = res;
                    upadatedName.billing.billing = responseUpdate.billing;
                    let billing = responseUpdate && responseUpdate.billing ? responseUpdate.billing.find(billing => billing.billingAccountId == response.accountId) : null;
                    if (billing) {
                      let digital = upadatedName && upadatedName.digitalBilling ? upadatedName.digitalBilling.find(billing => billing.value == response.accountId) : null;
                      if (digital && billing && digital.value == billing.billingAccountId) {
                        let type = digital.type == 'Invoice' ? 'I' : 'N';
                        let obj = this.pushBillingData(billing, digital.type, type);
                        const index = upadatedName.digitalBilling.findIndex((e) => e.value == response.accountId);
                        upadatedName.digitalBilling[index] = obj;
                      } 
                      // else {
                      let print = upadatedName && upadatedName.printBilling ? upadatedName.printBilling.find(billing => billing.value == response.accountId) : null;
                      if (print && billing && print.value == billing.billingAccountId) {
                        let type = print.type == 'Invoice' ? 'I' : 'N';
                        let obj = this.pushBillingData(billing, print.type, type);
                        const index = upadatedName.printBilling.findIndex((e) => e.value == response.accountId);
                        upadatedName.printBilling[index] = obj;
                      }
                      // }
                      if(upadatedName && upadatedName.items) {
                        for (let item in upadatedName.items) {
                          let itemsBillingArrayItem = (upadatedName.items[item] && upadatedName.items[item].billingArray) ? 
                            upadatedName.items[item].billingArray.find(billing => billing.value == response.accountId) : 
                            null;
                          if(itemsBillingArrayItem && billing && itemsBillingArrayItem.value == billing.billingAccountId) {
                            let type = itemsBillingArrayItem.type == 'Invoice' ? 'I' : 'N';
                            let obj = this.pushBillingData(billing, itemsBillingArrayItem.type, type);
                            const index = upadatedName.items[item].billingArray.findIndex((e) => e.value == response.accountId);
                            upadatedName.items[item].billingArray[index] = obj;
                          }
                        }
                      }
                    }
                    upadatedName.account = this.backupBilling.account;
                    this.indicatorStore.setLoading(true);
                    this.indicatorStore.update((state) => {
                      return {
                        indicator: upadatedName,
                        updatedFrom: "[BillingnewComponent] OnSubmitBiling1",
                        isloaded: true,
                      };
                    });
                    this.indicatorStore.setLoading();
                    this.logger.logSuccess("Successfully updated Billing Account")
                    if (this.accountType.toLowerCase() == 'map' || this.accountType == 'Monthly Advance Pay') {
                      window.open(this.payNowurl, '_blank')
                    }
                    this.spinnerService.hide();
                    this.dialogRef.close(upadatedName);
                  }, err => {
                    if (err && err.error && err.error.response && err.error.response.esbServiceFault) {
                      var errorObject = err.error.response.esbServiceFault;
                      var errorSystem = errorObject.sourceSystem;
                      var errorCode = errorObject.exceptionDetail[0].errorCode;
                      var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                      var errorMsg = "<strong>Transaction : " + (err.error.response.transactionid ? err.error.response.transactionid : err.error.response.transactionId) + "</strong><br><strong>" + errorSystem + ": </strong><strong>" + errorCode + ":</strong><br>" + errorDesc;
                      this.logger.logError(errorMsg);
                    }
                    this.spinnerService.hide();
                    this.dialogRef.close(upadatedName);
                  })

                }, err => {
                  if (err && err.error && err.error.response && err.error.response.esbServiceFault) {
                    var errorObject = err.error.response.esbServiceFault;
                    var errorSystem = errorObject.sourceSystem;
                    var errorCode = errorObject.exceptionDetail[0].errorCode;
                    var errorDesc = errorObject.exceptionDetail[0].errorDescription;
                    var errorMsg = "<strong>Transaction : " + (err.error.response.transactionid ? err.error.response.transactionid : err.error.response.transactionId) + "</strong><br><strong>" + errorSystem + ": </strong><strong>" + errorCode + ":</strong><br>" + errorDesc;
                    this.logger.logError(errorMsg);
                  }

                  this.indicatorStore.update((state) => {
                    return {
                      indicator: this.backupBilling,
                      updatedFrom: "[BillingnewComponent] updateKGENError",
                      isloaded: true,
                    };
                  });
                  this.spinnerService.hide();
                  this.dialogRef.close(upadatedName);
                });
            }
          }
        // }
        // else {
        //   if(response && response.data && (response.data.valid == "false")) {
        //     this.logger.logError("Invalid phone number")
        //   } else {
        //     this.logger.logError("Error while getting the phone number")
        //   }
        // }
    //  })
  }

  pushToItemBillingArray(upadatedName, obj) {
    for (let item in upadatedName.items) {
      if (
        upadatedName &&
        upadatedName.items &&
        upadatedName.items[item] &&
        Array.isArray(upadatedName.items[item].billingArray)
      ) {
        upadatedName.items[item].billingArray.push(obj);
      } else {
        console.error('Properties are undefined or not in the expected format.');
      }
    }
    // for (let item in upadatedName.items) {
    //   if (upadatedName && upadatedName.items && upadatedName.items[item] && upadatedName.items[item].billingArray) {
    //     upadatedName.items[item].billingArray.push(obj);
    //   }
    // }
    return upadatedName;
  }

  onUpdateAccountType(data) {
    this.accountType = data
  }

  private pushBillingData(billing, productType,billAccType) {
    let name = billing.billingName + " " + billing.billingAddress.streetAddress + " " + billing.billingAddress.cityName + " " + billing.billingAddress.stateAbbr + " " + billing.billingAddress.zip4;
    let billAddr = billing.billingAddress.streetAddress + ',' + billing.billingAddress.cityName + ',' + billing.billingAddress.stateAbbr + ',' + billing.billingAddress.zip5 + ',' + this.sharedData.account.address.country;
    let obj  = {
      value: billing.billingAccountId,
      name:name + ' - ' + billing.billingAccountId,
      businessEntity: billing.businessEntity,
      lastBilledAmount: this.Utils.showtwoDecimalDigit(billing.lastBilledAmount),
      digitalItemInd: billing.digitalItemInd,
      type: productType,
      // finalName: billing.billingName + '/' + billing.btn.telephoneType.NPA + '-' + billing.btn.telephoneType.COP + '-' + billing.btn.telephoneType.LINE + '/' + billAddr + '/' + billAccType + '/' + '$' + this.Utils.showtwoDecimalDigit(billing.lastBilledAmount) + '/' + billing.businessEntity + ' - ' + billing.billingAccountId
      finalName: billing.billingName + '/' + billing.btn + '/' + billAddr + '/' + billAccType + '/' + '$' + this.Utils.showtwoDecimalDigit(billing.lastBilledAmount) + '/' + billing.businessEntity + ' - ' + billing.billingAccountId
    };
    return obj;
  }
};
